.loading {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%,-50%); */
  width: 70px;
  height: 70px;
  border: 7px solid var(--secondary);
  border-top-color: transparent;
  margin: auto;
  border-radius: 50%;
  animation: loading ease 0.6s infinite;
}
@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
