@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CustomFont";
  src: url("./images/droid_arabic_kufi/DroidKufi-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "CustomFontBold";
  src: url("./images/droid_arabic_kufi/DroidKufi-Bold.ttf") format("truetype");
}
.font-semibold {
  font-family: "CustomFontBold", sans-serif;
  font-weight: 400 !important;
  font-size: 14px !important;
}

body {
  font-family: "CustomFont", sans-serif;
  direction: rtl;
  background-color: #f5f5f5;
  /* background-image: url(../src/svg/background.svg); */
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: 0 bottom;
  background-size: contain;
  cursor: default;
}

.scroll {
  scrollbar-width: none !important;
}

.bg {
  direction: rtl;
  background-image: url(../src/images/bg.png);
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
}
.bg-login {
  direction: rtl;
  background-image: url(../src/images/login_img_1.png);
  background-repeat: no-repeat;

  background-position: center;
  background-size: cover;
}

.border {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

title {
  background-color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
table {
  font-size: 13px !important;
}
svg {
  cursor: pointer !important;
}

.table-thead{
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(47,126,143,1) 0%, rgba(7,209,252,1) 87%);
}

.btns:hover{

  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(47,126,143,1) 0%, rgba(7,209,252,1) 87%) ;

}

.show:hover{
  background: #000;
    content: "الدفعات المقبولة";
}


