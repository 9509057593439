:root {
  --primary: white;
  --secondary: #003e52;
  --Third: #0099b4;
}

ul li a.active {
  color: #0d5069 !important;
  background-color: #EBEFF4;

}
.sidebar::-webkit-scrollbar {
  width: 0px;
}

